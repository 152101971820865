import { tags } from "@samedaycustom/core-ui";
import { EditItem } from "vendor/pages/Operations/Order/ViewOrder/OrderItems/EditItem";
import {
  OPERATION_VIEW_ORDER,
  OPERATION_VIEW_PRODUCTION,
  OPERATION_VIEW_SHIPPING,
} from "vendor/routes/screen";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { isEmpty, isEqual, transform } from "lodash";
import moment from "moment";
import allSettled from "promise.allsettled";
import { ThunkAction } from "redux-thunk";

import { ISDCInternalStatusSize, ISDCItem } from "@samedaycustom/types/order/@types/line";
import { DATE_FORMAT } from "./constants/Metrics.constants";
import { Actions, SELECTION_TYPES } from "@samedaycustom/types/app";
import { FilterValue } from "@samedaycustom/types/app/Operations";

/** checks if object is an object */
export const isObject = (value: any) => value !== null && typeof value === "object";

/**
 * checks if @param value is a string
 * @param value
 */
export const isString = (value: any) => value !== null && typeof value === "string";

export function difference(object: any, base: { [x: string]: any }) {
  return transform(object, (result, value, key: any) => {
    if (!isEqual(value, base[key])) {
      result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
    }
  });
}

export function jsDownload(
  data: string | ArrayBuffer | ArrayBufferView | Blob,
  filename: string,
  mime?: string,
  bom?: string
) {
  const blobData = typeof bom !== "undefined" ? [bom, data] : [data];
  const blob = new Blob(blobData, { type: mime || "application/octet-stream" });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
}

export const downloadUrl = (filePath: string): any => {
  // try {
  //     Axios.get(filePath).then(response => {
  //         jsDownload(response.data, filePath?.substr(filePath?.lastIndexOf("/") + 1))
  //     })
  // } catch (error) {}
  if (!filePath) return null;
  const a = document.createElement("A");
  a.setAttribute("href", filePath);
  a.setAttribute("download", filePath?.substr(filePath.lastIndexOf("/") + 1));
  a.setAttribute("target", "_blank");
  // a.setAttribute("target", "_self")
  a.setAttribute("rel", "noopener noreferrer");
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const mutateSelection = (selection: SELECTION_TYPES[], id: string | number) =>
  selection.map((element) => {
    if (id === "none") {
      return {
        ...element,
        selected: element.id === id ? !element.selected : false,
      };
    }

    if (element.id === "none") element.selected = false;
    if (element.id === id)
      return {
        ...element,
        selected: !element.selected,
      };

    return element;
  });

export const formatMobileChange = (e: any, _name: string) => {
  const old_value = e.target.value;
  const value = old_value;
  const phone = parsePhoneNumberFromString(value);

  return phone && phone.formatInternational();
};

export function isLetter(str: string) {
  return str.length === 1 && str.match(/[a-z]/i) && str.toLowerCase() != str.toUpperCase();
}
export const handleMobileChange = (
  e: any,
  name: string,
  setFieldValue: (arg0: string, arg1: string) => void,
  format?: "International" | "NATIONAL" | "IDD" | "INTERNATIONAL"
) => {
  const old_value = e.target.value;

  if (old_value.length > 13) return;

  const value = old_value;
  const phone = parsePhoneNumberFromString(value);

  setFieldValue(name, phone && phone.format(format));
};

// sort days according correctly
export const sortDays = (days: ({ day: string } | any)[], list: string[]) => {
  const DAY_OF_WEEK = 6;

  const sortedList = list.slice(DAY_OF_WEEK).concat(list.slice(0, DAY_OF_WEEK));
  return days.sort((a, b) => {
    if (sortedList.indexOf(a.day) > sortedList.indexOf(b.day)) return 1;
    if (sortedList.indexOf(a.day) < sortedList.indexOf(b.day)) return -1;
    return 0;
  });
};

export const listOfDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const days = {
  sun: "Sunday",
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
};
export const SIZES = ["M", "S", "L", "XL", "XXL", "XXXL"];
// deep trims an object
export const deepTrim = (values: { [x: string]: any }) => {
  Object.keys(values).map(
    (k) =>
    (values[k] =
      typeof values[k] === "string"
        ? values[k].trim()
        : typeof values[k] === "object"
          ? deepTrim(values[k])
          : values[k])
  );
  return values;
};

export const handleNumberValue = (
  e: any,
  name: string,
  setFieldValue: (arg0: string, arg1: any) => void
): any => {
  const value = e.target.value;
  if (isNaN(Number(value))) return null;

  setFieldValue(name, value);

  return null;
};

export const handleTimeChange = (
  e: { target: { value: any } },
  name: any,
  length = 12,
  setFieldValue: (arg0: any, arg1: any) => void
) => {
  const { value } = e.target;
  if (isNaN(Number(value)) || Number(value) > length) return;
  setFieldValue(name, value);
};

export const parseUrl = (url: string) =>
  JSON.parse("{\"" + url.replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}", function (key, value) {
    return key === "" ? value : decodeURIComponent(value);
  });

export const getDaysRange = (date1: moment.MomentInput, date2: moment.MomentInput) => {
  const date: string[] = [];
  if (date1 && date2) {
    const days = Math.abs(moment(date2).diff(moment(date1), "days")) + 1;
    for (let i = 0; i < days; i++) {
      date.push(
        moment(date1)
          .add(i, "days")
          .format("DD MMM YYYY")
      );
    }
    return date;
  }

  return date;
};

export const validateAndUpload = (
  input: File,
  callback: (arg0: HTMLImageElement) => any,
  error: OnErrorEventHandlerNonNull
) => {
  const URL = window.URL || window.webkitURL;
  const file = input;
  if (file) {
    const image = new Image();

    image.onload = () => callback(image);
    image.onerror = error;
    image.src = URL.createObjectURL(file);
  }
};

/**
 * settle all promises
 */
export const settlePromiseAsync = (
  result: (ThunkAction<Promise<any>, undefined, undefined, Actions> | Promise<any>)[]
): Promise<any> =>
  allSettled(result)
    .then((res: { status: string; data?: any }[]) => {
      const completeResult = res.filter((el) => el.status === "fulfilled");
      if (completeResult.length >= result.length) return Promise.resolve(completeResult);
      else return Promise.reject(res);
    })
    .catch((err: any) => Promise.reject(err));

export const sortSize = (a: ISDCItem, b: ISDCItem) => {
  if (a.size.toUpperCase() > b.size.toUpperCase()) {
    return -1;
  }
  if (b.size.toUpperCase() > a.size.toUpperCase()) {
    return 1;
  }
  return 0;
};

/**
 * gets due date from rush option
 * @param optionText
 * @param orderDate
 */
export const getDueDateFromRushOption = (optionText?: string, orderDate?: number | Date) => {
  if (!optionText) return null;

  const regex = /(?:\+.*)\+/g;
  const [, day] = regex.exec(optionText) || [];

  if (!day) return null;

  return moment(orderDate)
    .add(Number(day), "days")
    .toDate();
};

/**
 * validates a type file if it matches a file type
 * @param file
 * @param match
 */
export function validateFileType(file: File, match: string[]) {
  const filetype = file.type;
  if (match.includes(filetype.toLocaleLowerCase())) return true;
  return false;
}

/**
 * returns query for closing record delivery
 * @param location History Location
 * @param order_id
 * @param line_id
 * @deprecated
 */
export const recordDeliveryCloseQuery = (
  location: any,
  order_id: string,
  deco_id: string,
  locationId: string
) => {
  const state =
    typeof location?.state !== "undefined" &&
    location?.state.hasOwnProperty("type") &&
    location?.state;

  if (!state) return null;
  let query = null;
  const line_id = state?.line_id;

  switch (state?.type) {
    case "shipping":
      query = `${OPERATION_VIEW_SHIPPING}/${deco_id}/${order_id}/${locationId}`;
      break;
    case "order":
      query = `${OPERATION_VIEW_ORDER}/${deco_id}/${order_id}/${locationId}`;
      break;
    case "production":
      query = `${OPERATION_VIEW_PRODUCTION}/${deco_id}/${order_id}/${locationId}?item=${line_id}`;
      break;
    default:
      break;
  }

  return query;
};

export function addZeroes(num: string) {
  const dec = num.split(".")[1];
  const len = dec && dec.length > 2 ? dec.length : 2;
  return Number(num).toFixed(len);
}

/**
 *
 * converts pounds to ounce and pounds
 * @param weightInPounds
 */
export const convert = (weightInPounds: { toString: () => string }) => {
  const weightsplit = weightInPounds.toString().split(".");
  const pounds = Number(weightsplit[0]) || 0;
  const ounce = Number("0." + weightsplit[1]) * 16 || 0;

  return { pounds, ounce };
};

export const getDigitFromString = (string: string) => Number(string.match(/\d+/)[0]);

/**
 *
 * reform sizes of @typedef ISDCInternalStatusSize to @interface ProductionStatusValueType
 */
export const formatSizesToSizeObject = <T extends ISDCInternalStatusSize | EditItem>(sizes: T[]) =>
  sizes.reduce(function (result, item) {
    const key = item.size;
    result[key] = item;
    return result;
  }, {});

/**
 *
 * @param filteredValues
 * displaying filter tags on order tables
 */
export const displaytagOptions = (filteredValues: FilterValue) =>
  filteredValues &&
  Object.entries(filteredValues)

    // map through each object and format text to tags
    .map((el) => {
      if (el[1] === "All") return null;

      const element = {
        id: el[0],
        text: `${el[0]}: ${el[1]}`,
        tagStyle: {
          backgroundColor: "#F0F4F8",
          color: "#243B53",
        },
      };

      if (el[0] === "order" && !isEmpty(el[1]["start"]) && !isEmpty(el[1]["end"])) {
        element.text = `Order Date: ${moment(el[1]["start"]).format(DATE_FORMAT) ||
          "N/A"} -  ${moment(el[1]["end"]).format(DATE_FORMAT) || "N/A"}`;

        return element;
      } else if (el[0] === "due" && !isEmpty(el[1]["start"]) && !isEmpty(el[1]["end"])) {
        element.text = `Due Date: ${moment(el[1]["start"]).format(DATE_FORMAT) ||
          "N/A"} -  ${moment(el[1]["end"]).format(DATE_FORMAT) || "N/A"}`;

        return element;
      } else if (el[0] === "delivery" || el[0] === "status") {
        if (tags[`${el[1]}`]?.text) {
          element.text = `${el[0]}: ${tags[`${el[1]}`]?.text}`;
          return element;
        }
        return null;
      }

      return null;
    })
    .filter(Boolean)
    .filter((el) => el["text"] !== "");

export const getSizeNameFromCode = (code: string) => {
  if (!code) return "";
  const sizes: any = {
    Y: "Youth",
    M: "Medium",
    A: "Adult",
    S: "Small",
    L: "Large",
    X: "Extra",
  };

  const codeArr = code?.split("");
  const sizeArr =
    codeArr?.map((str: string) => {
      const validatedStr = str.toUpperCase();
      for (const size in sizes) {
        if (validatedStr === size) {
          return sizes[validatedStr];
        }
      }
      return validatedStr;
    }) ?? [];

  const sizeStr = sizeArr.join(" ");
  return sizeStr;
};

export const loadZenDesk = (authed: boolean) => {
  const scriptId = "ze-snippet";
  if (!authed) {
    if (document.getElementById(scriptId)) {
      return;
    }

    const scriptElem = document.createElement("script");
    scriptElem.id = scriptId;
    scriptElem.async = true;
    scriptElem.src =
      "https://static.zdassets.com/ekr/snippet.js?key=ea339caa-956d-409a-a1a1-236dc07e21e6";
    document.head.appendChild(scriptElem);

    scriptElem.onload = () => {
      // @ts-ignore
      if (window.zE) {
        // @ts-ignore
        window.zE('messenger:set', 'cookies', true);
      }
    };
  }
};

export const removeZenDesk = () => {
  const scriptId = "ze-snippet";
  const zendeskScript = document.getElementById(scriptId);
  if (zendeskScript) {
    zendeskScript.remove();
  }

  // @ts-ignore
  if (window.zE) {
    // @ts-ignore
    window.zE('messenger:set', 'cookies', false);
  }
};
